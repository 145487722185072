import type {
  ButtonProps as ChakraButtonProps,
  EditableProps,
  FlexProps,
  ModalContentProps,
  PlacementWithLogical,
} from '@chakra-ui/react'
import type {
  CurrentInning,
  IBallModel,
  IBallStoreModel,
  IBattingPartnershipModel,
  IBattingPerformanceModel,
  IBowlingPerformanceModel,
  ICoordinateModel,
  IDLSSuspensionModel,
  IFieldingPlacementStoreModel,
  IFieldingPlayerModel,
  IInningModel,
  IMatchConditionsModel,
  IMatchDatesModel,
  IMatchModel,
  IMatchNotesModel,
  IMatchOfficialModel,
  IMatchPlayerModel,
  IMatchTeamModel,
  IOfficialModel,
  IPlayerModel,
  ISummaryMatchModel,
  ITimelineEventModel,
  ITimelineMatchMapModel,
  IVenueEndModel,
} from '@clsplus/cls-plus-data-models'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { MouseEvent, ReactElement, ReactNode } from 'react'

import type {
  BatterChangeType,
  BowlerChangeType,
  CascadeEditProps,
  DropdownCallbackArgs,
  DropdownOption,
  FieldingPositionPositionsInterface,
  HandleSelectPositionArgs,
  HandleUpdatePlacementsArgs,
  HeadingType,
  InningInOrder,
  PowerPlayInterface,
  TriggerEditBallArgs,
  UmpireEnds,
} from '../../types'
import type { IClspMatchModel, IInningMetaModel, IMatchSettingsModel, ISettingsModel, ISocketStore } from '../models'

export type ClspMode = 'core' | 'advanced' | 'fielding'

export interface ExtrasProps {
  inning: IInningModel
  noBallRuns: number | null
}

export interface PartnershipsProps {
  inning: IInningModel
}

export interface TeamSummaryProps {
  game?: IClspMatchModel
  team: IMatchTeamModel | undefined
  versusLabel?: string
  isBatting: boolean
  teamOnly?: boolean
  gamesScreen?: boolean
}

export interface SettingsContainerProps {
  game: IClspMatchModel
  mode: ClspMode
  isSetup?: boolean
  isScoringManually?: boolean
  currentBall?: IBallModel
  timelineEvents?: ITimelineMatchMapModel
  settingsConfirmed: boolean
  setSettingsConfirmed?: React.Dispatch<React.SetStateAction<boolean>>
  teamsConfirmed: boolean
  setTeamsConfirmed?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface MatchSettingsProps {
  game: IClspMatchModel
  mode: ClspMode
  isSetup?: boolean
}

export interface BallSummaryProps {
  mode: ClspMode
  game: IClspMatchModel
  ball: IBallModel
  lastBall?: IBallModel
  detailed?: boolean
  enabled: boolean
  editBall?: boolean
  backgroundColor?: string
  commentaryChanged: boolean
  setCommentaryChanged: React.Dispatch<React.SetStateAction<boolean>>
  isDisabled?: boolean
}

export interface BowlingProps {
  inning: IInningModel
  game: IClspMatchModel
  scoreManually?: boolean
  ballsPerOver: number
  triggerInningsState: () => void
  setDeletePerf?: React.Dispatch<React.SetStateAction<IBattingPerformanceModel | IBowlingPerformanceModel | null>>
  setDeletePerfOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface TeamNamesProps {
  game: IClspMatchModel
  mode: ClspMode
}

export interface TossProps {
  game: IClspMatchModel
  isDisabled: boolean
  timelineEvents?: ITimelineMatchMapModel
}

export interface DlsSuspensionProps {
  game: IClspMatchModel
  inningsId: string
  data: IDLSSuspensionModel[]
  inSuperOver: boolean
}

export interface DlsProps {
  game: IClspMatchModel
  inningsInOrder?: InningInOrder[]
  dlsOpen: boolean
  setDlsOpen: React.Dispatch<React.SetStateAction<boolean>>
  inSuperOver: boolean
}
export interface EndMatchProps {
  game: IClspMatchModel
  mode: ClspMode
  height?: string
  isWhite?: boolean
  isSelectPlayer?: boolean
  isScoringManually?: boolean
  timelineEvents?: ITimelineMatchMapModel
  handleValidate?: () => boolean
}

export interface ShortScorecardProps {
  inning?: IInningModel
  game: IClspMatchModel
  ball?: IBallModel
  mode: ClspMode
  scoresOnly?: boolean
  batterPerformance?: IBattingPerformanceModel
  triggerNewBatter?: (type: BatterChangeType) => void
  triggerNewBowler?: (type: BowlerChangeType) => void
  setBatterPerformance?: React.Dispatch<React.SetStateAction<IBattingPerformanceModel | undefined>>
  setBowlerPerformance?: React.Dispatch<React.SetStateAction<IBowlingPerformanceModel | undefined>>
  handlePenaltyRuns?: ({ value }: { value: string }) => void
  isDisabled?: boolean
  editBall?: boolean
  inBall?: boolean
  insertingBall?: boolean
  closedInning?: boolean
  endOver?: boolean
}

type EditableFieldTextProps = {
  type?: never
  onChange: (value: string) => void
}

type EditableFieldNumberProps = {
  type: 'number'
  onChange: (value: number) => void
}

export type EditableFieldProps = {
  target?: string
  value: string | number
  width?: number | string
  height?: number | string
  textAlign?: EditableProps['textAlign']
  marginLeft?: number | string
  pattern?: string
  errorMessage?: string
  background?: string
  label?: string
  ['data-testid']?: string
} & (EditableFieldTextProps | EditableFieldNumberProps)

interface RangeStepperRangeProps {
  start: number
  end: number
  increment: number
  incrementValues?: number
}
export interface RangeStepperProps {
  id: string
  title: string
  value: number
  min?: number
  max?: number
  range: RangeStepperRangeProps
  isUnlocked?: boolean
  isEditing?: boolean
  modalContentProps?: Partial<ModalContentProps>
  onChange: (value: number) => void
}
export interface ArrivalProps {
  ball: IBallModel
  editBall: boolean
  incompleteData: boolean
}
export interface WagonWheelProps {
  ball: IBallModel
  editBall: boolean
  incompleteData: boolean
}
export interface PitchMapProps {
  ball: IBallModel
  editBall: boolean
  incompleteData: boolean
}

export type ButtonProps = (
  | {
      as: ChakraButtonProps['as']
      onClick?: never
    }
  | {
      as?: never
      onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
    }
) &
  Omit<ChakraButtonProps, 'onClick' | 'as'>

export interface SubHeadingProps {
  text: string
  icon?: IconProp
  centered?: boolean
  secondary?: boolean
  tertiary?: boolean
  quaternary?: boolean
  bold?: boolean
  noPadding?: boolean
  warning?: boolean
  buttonProps?: {
    height?: ChakraButtonProps['height']
    onClick: ButtonProps['onClick']
    text: string
  }
}
interface FieldingIncompleteDataProps {
  fielder: boolean
  byeBlame: boolean
}

export interface FieldingProps {
  ball: IBallModel
  game: IClspMatchModel
  editBall?: boolean
  incompleteData: FieldingIncompleteDataProps
}
export interface FieldingPlacementsProps {
  ball: IBallModel
  lastBall?: IBallModel
  inBall?: boolean
  editBall?: boolean
  cancelEditBall?: (endOfOver?: boolean, resyncingBall?: boolean) => void
  deadBall?: (event: MouseEvent<HTMLButtonElement>) => void
  triggerNewBowler?: (type: BowlerChangeType) => void
  triggerNewFielder?: (playerMpId: string | null | undefined) => void
  fieldHasChanged?: boolean
  setFieldHasChanged?: React.Dispatch<React.SetStateAction<boolean>>
  handleCreateFielder?: (
    params: PositionParamsProps,
    firedByDifficultyRating: boolean
  ) => IFieldingPlayerModel | undefined
  handleRunOutRoles?: (fielderObject?: IFieldingPlayerModel) => void
  wagonWheeling?: boolean
  setWagonWheeling?: React.Dispatch<React.SetStateAction<boolean>>
  completeChangeBowler?: (player: IMatchPlayerModel) => void
  resetCoordinates?: ICoordinateModel | null
  setResetCoordinates?: React.Dispatch<React.SetStateAction<ICoordinateModel | null>>
  inBallBasicsEventsDone?: () => boolean
  inBallShotLocationDone?: () => boolean
  inBallFielderInfoDone?: () => boolean
  inBallNonFieldedWicket?: boolean
  isCurrentInnings?: boolean
  actionsDisabled?: boolean
}

export interface FieldingPlacementsFieldsProps {
  ball: IBallModel
  fieldHasChanged?: boolean
  setFieldHasChanged?: React.Dispatch<React.SetStateAction<boolean>>
  handleUpdatePlacements: ({ createFavourite, updateFavourite, label, subtitle }: HandleUpdatePlacementsArgs) => void
  completeChangeBowler?: (player: IMatchPlayerModel) => void
}

export interface FieldingPlacementsPositionsProps {
  selectingPositionParams: PositionParamsProps | null
  viablePositions: FieldingPositionPositionsInterface[] | null
  handleSelectPosition: ({ playerMpId, fieldingPositionId, isWicketkeeper }: HandleSelectPositionArgs) => void
  closeSelectPosition: () => void
}

export interface FieldingPlacementsOverlayProps {
  ball: IBallModel
  setFieldHasChanged?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface PositionParamsProps {
  x?: number
  y?: number
  playerMpId?: string | null | undefined
  playerMpName?: string | null | undefined
  fieldingPositionId?: number | null | undefined
  shirtNumber?: number | null | undefined
  difficultyRating?: number | null | undefined
}

export interface FieldingPlacementsFielderProps {
  ball: IBallModel
  editBall?: boolean
  inBall?: boolean
  viablePositions: FieldingPositionPositionsInterface[] | null
  selectingPlayerParams: PositionParamsProps | null
  handleSelectPosition: ({ playerMpId, fieldingPositionId, isWicketkeeper }: HandleSelectPositionArgs) => void
  triggerNewBowler?: (type: BowlerChangeType) => void
  triggerNewFielder?: (playerMpId: string | null | undefined) => void
  handleCreateFielder?: (
    params: PositionParamsProps,
    firedByDifficultyRating: boolean
  ) => IFieldingPlayerModel | undefined
  handleRunOutRoles?: (fielderObject?: IFieldingPlayerModel) => void
  setFieldHasChanged?: React.Dispatch<React.SetStateAction<boolean>>
}

export type SliderProps = {
  value: number
  min: number
  max: number
  increment: number
  labels?: string[]
  isDisabled?: boolean
  onChange: (value: number) => void
} & Omit<FlexProps, 'children' | 'onChange'>

export interface StepperProps {
  id: string
  value: number
  labels?: string[]
  min?: number | null
  max?: number | null
  increment?: number[]
  isRatio?: boolean
  ratioSeparator?: string
  isUnlocked?: boolean
  isEditing?: boolean
  isDisabled?: boolean
  isWhite?: boolean
  ignoreState?: boolean
  onChange: (value: number, operation: 'add' | 'subtract') => void
}

export interface StepperCallbackProps {
  value: string
}

export interface DropdownProps {
  id?: string
  target?: string
  icon?: IconProp
  options: DropdownOption[] | DropdownOption[][]
  value: string | ReactElement
  origValue?: string | ReactElement
  ignoreState?: boolean
  onChange: (args: DropdownCallbackArgs) => void
  onOpen?: () => void
  onClose?: () => void
  globalDisable?: boolean
  height?: string
  hideCheck?: boolean
  isClearable?: boolean
  isEditing?: boolean
  isWhite?: boolean
  justifyContent?: string
  width?: string
  listHeight?: string
  listWidth?: string
  fontSize?: string
  preserveCase?: boolean
  forceChanged?: boolean
  truncateButton?: number
  isIncomplete?: boolean
  buttonDisable?: boolean
  placement?: PlacementWithLogical
  lastOptionSeparated?: boolean
  colorScheme?: string
  defaultIsOpen?: boolean
  ['data-testid']?: string
}
export interface SpeedSpinProps {
  ball: IBallModel
  editBall?: boolean
}
interface ShotIncompleteDataProps {
  type: boolean
  contact: boolean
}
export interface ShotProps {
  mode: ClspMode
  ball: IBallModel
  incompleteData: ShotIncompleteDataProps
  isPrimaryEditCheck: () => void
}

export interface TrajectoryProps {
  ball: IBallModel
  mode: ClspMode
  isPrimaryEditCheck?: () => void
  noShot?: boolean
}

export interface PredictiveProps {
  mode: ClspMode
  ball: IBallModel
  editBall: boolean
  updatePredictiveMessaging: (type: string) => void
}
export interface DeliveryProps {
  ball: IBallModel
}

export interface AdvancedScoringProps {
  ball: IBallModel
  game: IClspMatchModel
  editBall?: boolean
  isPrimaryEditCheck: () => void
}

export interface BasicScoringProps {
  ball: IBallModel
  game: IClspMatchModel
  mode: ClspMode
  editBall?: boolean
  insertBall?: boolean
  ballRunsVal: number | null
  setBallRunsVal: React.Dispatch<React.SetStateAction<number | null>>
  ballExtrasVal: string | null
  setBallExtrasVal: React.Dispatch<React.SetStateAction<string | null>>
  isPrimaryEditCheck?: () => void
}

export interface ScoringProps {
  game: IClspMatchModel
  balls: IBallStoreModel
  appSettings: ISettingsModel
  timelineEvents: ITimelineMatchMapModel | undefined
  fieldingPlacements: IFieldingPlacementStoreModel
}

export interface InGameHeaderProps {
  preGame?: boolean
  preInnings?: boolean
  mode?: ClspMode
}

export interface SocketLightProps {
  connected: boolean
  primary: boolean
  assignedRole: boolean
  setPromptUser: (value: boolean) => void
}

export interface ActivityLogProps {
  game: IClspMatchModel
  balls: IBallModel[]
  relevantTimelineEvents?: ITimelineEventModel[]
  mode: ClspMode
  manualEndOver: () => void
  insertBall?: (overNumber: number, ballNumber: number) => void
  editBall?: IBallModel
  triggerEditBall?: (args: TriggerEditBallArgs) => void
  cancelEditBall?: (endOfOver?: boolean, resyncingBall?: boolean) => void
  betweenOvers?: boolean
  triggerUndo?: (ball: IBallModel, undoOnly?: boolean) => void
  triggerUndoEvent?: (event: ITimelineEventModel, relatedEvent?: ITimelineEventModel) => void
  triggerMissedBall?: () => void
  triggerNewBowler?: (type: BowlerChangeType, ballOver?: IBallModel) => void
  setBowlerPerformance?: React.Dispatch<React.SetStateAction<IBowlingPerformanceModel | undefined>>
  inningsInOrder?: InningInOrder[]
  currentInning?: CurrentInning
  setCurrentInning?: React.Dispatch<React.SetStateAction<CurrentInning | undefined>>
  closedInning?: boolean
  editingDisabled?: boolean
  actionsDisabled?: boolean
}

export interface EventItemProps {
  event: ITimelineEventModel
  mode?: string
  editBall?: IBallModel
  hasUndo?: boolean
  undoDisabled?: boolean
  triggerUndo?: (event: ITimelineEventModel) => void
}

export interface BallItemProps {
  ball: IBallModel
  editBall?: IBallModel
  triggerEditBall?: (args: TriggerEditBallArgs) => void
  mode: ClspMode
  triggerUndo?: (ball: IBallModel, undoOnly?: boolean) => void
  handleBallClick: (ball: IBallModel, isNewestOver: boolean, isNewestBallInOver: boolean) => void
  hasUndo?: boolean
  undoDisabled?: boolean
  noBallValue?: number | null
}

export interface UndoItemProps {
  mode?: string
  matchId?: string
  editBall?: IBallModel
  currentBall?: IBallModel
  onClick?: () => void
}

export interface FieldingMiscProps {
  ball: IBallModel
  editBall?: boolean
  incompleteData?: boolean
}
export interface FieldingMiscOverthrowsProps {
  mode?: ClspMode
  ball: IBallModel
  editBall?: boolean
}
export interface DataRowsProps {
  data: IInningModel
  type: string
  cols: HeadingType[]
  short: boolean
}

export interface HeaderRowProps {
  headings: Array<HeadingType>
}

interface PowerPlayPropsInterface {
  triggers?: PowerPlayInterface[] | null
  oversBowled?: string | null
  dlsActive?: boolean | null
}

export interface ScoringAlertsProps {
  mode: ClspMode
  activeInningMetaData?: IInningMetaModel
  scorePassedIsOpen: boolean
  setScorePassedIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  oversPassedIsOpen: boolean
  setOversPassedIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleEndInning: (type: string, clearNotOutRetirements?: boolean, dismissalType?: string) => void
  wicketsPassedIsOpen: boolean
  setWicketsPassedIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  powerPlayStartIsOpen: boolean
  setPowerPlayStartIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  cascadeEditIsOpen: boolean
  setCascadeEditIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleCascadeEdit: (args: CascadeEditProps) => void
  cascadeEditProps: CascadeEditProps | undefined
  handleStartPowerPlay: (value: string | undefined, endOnly?: boolean) => void
  powerPlayProps: PowerPlayPropsInterface | null
  venueEndsIsOpen: boolean
  setVenueEndsIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleVenueEnds: (venueEnd: IVenueEndModel | null) => void
  venueEndsProps?: IVenueEndModel[] | null | undefined
  umpireEndsIsOpen: boolean
  setUmpireEndsIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleUmpireEnds: (umpireControl: IMatchOfficialModel | null, umpireNonControl: IMatchOfficialModel | null) => void
  umpireEnds: UmpireEnds | undefined
  insertingBall?: boolean
  cancelDismissalOrLastBall: (isDismissal?: boolean) => void
  currentBall?: IBallModel
}

export interface SettingsProps {
  mode: ClspMode
}

export interface BowlingSetupProps {
  mode: ClspMode
}

export interface BattingSetupProps {
  mode: ClspMode
}

export interface GameSummaryProps {
  status: string
  venueName?: string
  matchDates?: IMatchDatesModel[]
  dataProvider: string | null
}

export type GameDetailProps =
  | {
      type: 'local'
      game: IClspMatchModel
      idx: number
      onClick: (game: IClspMatchModel) => void
    }
  | {
      type: 'system'
      game: ISummaryMatchModel
      idx: number
      onClick: (game: ISummaryMatchModel, status: string | undefined) => Promise<void>
    }

export interface ScorebookProps {
  game: IClspMatchModel
  appSettings: ISettingsModel
}

export interface MetricsProps {
  game: IClspMatchModel
}

export interface SelectPlayerProps {
  title?: string
  subtitle?: string
  primaryButton?: string
  cancelButton?: string
  mode: ClspMode
  players: IMatchPlayerModel[] | undefined
  playersDisabled?: IMatchPlayerModel[] | undefined
  playersNotRetiredNotOut?: IMatchPlayerModel[] | undefined
  playerPreselected?: IMatchPlayerModel | null | undefined
  playerToSwap?: IMatchPlayerModel | null | undefined
  onChange: (player: IMatchPlayerModel, type?: string, playerToSwap?: IMatchPlayerModel | null) => void
  onCancel?: () => void
  handleEndInning?: (type: string, clearNotOutRetirements?: boolean, dismissalType?: string) => void
  controlsPanelProps?: ControlsPanelProps
  setup?: boolean
  type?: string // used for dismissal types
}

export interface WicketSectionProps {
  ball: IBallModel
  game: IClspMatchModel
  mode: ClspMode
  editBall?: boolean
  insertBall?: boolean
  isPrimaryEditCheck?: () => void
  setBallRunsVal: React.Dispatch<React.SetStateAction<number | null>>
  setBallExtrasVal: React.Dispatch<React.SetStateAction<string | null>>
}

export interface ReviewSectionProps {
  activeInning?: IInningModel
  ball: IBallModel
  mode: ClspMode
  editBall?: boolean
  updateReviewMessaging: () => void
}

export interface BetweenInningProps {
  game: IClspMatchModel
  timelineEvents: ITimelineMatchMapModel | undefined
  mode: ClspMode
}

export interface FieldingEventsProps {
  ball: IBallModel
}

export interface FieldingBasicsProps {
  ball: IBallModel
  game: IClspMatchModel
  editBall: boolean
  mode: ClspMode
  isDisabled?: boolean
}

export interface FieldingBasicsFieldedProps {
  mode: ClspMode
  ball: IBallModel
  fielders?: IMatchPlayerModel[]
  changeFirstFielder?: (value: string, clearValue?: boolean) => void
  disabled?: boolean
}

export interface MatchBreakProps {
  game: IClspMatchModel
  timelineEvents?: ITimelineMatchMapModel
  placement?: PlacementWithLogical
  truncateButton?: number
  isWhite?: boolean
  height?: string | undefined
  defaultValue?: string
}

export interface ControlsPanelProps {
  game: IClspMatchModel
  currentBall?: IBallModel
  setCurrentBall: React.Dispatch<React.SetStateAction<IBallModel | undefined>>
  mode: ClspMode
  inning?: IInningModel
  timelineEvents?: ITimelineMatchMapModel
  inningsInOrder?: InningInOrder[]
  isDisabled?: boolean
  isSelectPlayer?: boolean
  editBall?: IBallModel
  cancelEditBall?: (endOfOver?: boolean, resyncingBall?: boolean) => void
  currentInning?: CurrentInning
  setCurrentInning?: React.Dispatch<React.SetStateAction<CurrentInning | undefined>>
  closedInning?: boolean
  setClosedInning?: React.Dispatch<React.SetStateAction<boolean>>
  closeEditBallMode?: () => void
  setEndOver?: React.Dispatch<React.SetStateAction<boolean>>
  setInBall?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface WeatherProps {
  game: IClspMatchModel
  mode: ClspMode
  conditions?: IMatchConditionsModel
}

export interface PitchProps {
  game: IClspMatchModel
  mode: ClspMode
  conditions?: IMatchConditionsModel
}

export interface VenueProps {
  game: IClspMatchModel
  mode: ClspMode
  currentBall?: IBallModel
  conditions?: IMatchConditionsModel
}

export interface MetricProps {
  id: string
  game: IClspMatchModel
}

export type CustomButtonProps = {
  children: ReactNode
  onClick: () => void
  isDisabled?: boolean
  isFlex?: boolean
  isFirst?: boolean
  isMiddle?: boolean
  isLast?: boolean
  isWhite?: boolean
  target?: string | null
  isActive?: boolean
  hideCheck?: boolean
  width?: string
  height?: string
  textNotCentred?: boolean
  marginBottom?: string
  paddingX?: string
  paddingXOuter?: string
  ['data-testid']: string | undefined
}

export type SimpleStateButtonProps<TValue = void> = Omit<CustomButtonProps, 'onClick'> & {
  target?: string | null
} & (
    | {
        isSwitch: true
        value: boolean
        onClick: ({ value, target }: { value: boolean; target?: string | null }) => void
      }
    | {
        isSwitch?: never
        value: TValue
        onClick: ({ value, target }: { value: TValue; target?: string | null }) => void
      }
  )

export interface BallDescriptionProps {
  mode: ClspMode
  ball: IBallModel
  game: IClspMatchModel
  lastBall?: IBallModel
  ballStore: IBallStoreModel
  editBall?: boolean
  isNewestBall?: boolean
  isNewestOver?: boolean
  preBall?: boolean
  timelineEvents: ITimelineMatchMapModel | undefined
  isDisabled?: boolean
}

export interface BowlerApproachProps {
  mode: ClspMode
  ball: IBallModel
  isDisabled?: boolean
}

export interface FreeHitProps {
  mode: ClspMode
  game: IClspMatchModel
  ball: IBallModel
  isDisabled?: boolean
}

export interface DescriptionSectionProps {
  game: IClspMatchModel
  ball: IBallModel
  lastBall?: IBallModel
  cancelEditBall?: (endOfOver?: boolean, resyncingBall?: boolean) => void
  preBall?: boolean
  endOver?: boolean
  editBall?: IBallModel
  editBallProps?: { isNewestOver?: boolean; isNewestBall?: boolean }
  bowlerRunningIn?: (event: MouseEvent<HTMLButtonElement>) => void
  triggerEndOver?: () => void
  deadBall?: (event: MouseEvent<HTMLButtonElement>) => void
  commentaryChanged: boolean
  setCommentaryChanged: React.Dispatch<React.SetStateAction<boolean>>
  timelineEvents: ITimelineMatchMapModel | undefined
  isDisabled?: boolean
  closedInning?: boolean
  cancelledDismissal?: boolean
  insertingBall?: boolean
}

export interface TeamListProps {
  game: IClspMatchModel
  team: IMatchTeamModel | undefined
  currentBall?: IBallModel
  mode: ClspMode
  records: IMatchPlayerModel[]
  setRecords: React.Dispatch<React.SetStateAction<IMatchPlayerModel[]>>
  setTeamsUpdated: React.Dispatch<React.SetStateAction<boolean>>
  isSetup?: boolean
}

interface PersonListPropsProps {
  type: string
  teamId?: string
}

export interface PersonListProps {
  data: (IPlayerModel | IOfficialModel)[] | undefined
  existingData?: (IMatchPlayerModel | IMatchOfficialModel)[] | undefined
  isLoading: boolean
  props: PersonListPropsProps | null
  personListOpen: boolean
  setPersonListOpen: React.Dispatch<React.SetStateAction<boolean>>
  personListAdding: boolean
  setPersonListAdding: React.Dispatch<React.SetStateAction<boolean>>
  onAddPerson: (id: string, type?: number | undefined) => Promise<void>
}

export interface PartnershipCardProps {
  partnership: IBattingPartnershipModel
  inningsMatchOrder: number
}

export interface OfficialsProps {
  game: IClspMatchModel
  mode: ClspMode
  currentBall?: IBallModel
  onManageClick: () => void
}

export interface ActionButtonsProps {
  mode: ClspMode
  type: string
  inning: IInningModel
  ball?: IBallModel
  game: IClspMatchModel
  editBall?: boolean
}

export interface CurrentBowlersProps {
  inning: IInningModel
  triggerNewBowler?: (type: BowlerChangeType) => void
  setPerformance?: React.Dispatch<React.SetStateAction<IBowlingPerformanceModel | undefined>>
  isDisabled?: boolean
  editBall?: boolean
}

export interface CurrentBattersProps {
  inning: IInningModel
  triggerNewBatter?: (type: BatterChangeType) => void
  performance?: IBattingPerformanceModel
  setPerformance?: React.Dispatch<React.SetStateAction<IBattingPerformanceModel | undefined>>
  isDisabled?: boolean
  editBall?: boolean
}

export interface BattingProps {
  inning: IInningModel
  game: IClspMatchModel
  mode: ClspMode
  matchSettings: IMatchSettingsModel
  scoreManually?: boolean
  triggerInningsState: () => void
  setEditDismissalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setEditDismissalPerf: React.Dispatch<React.SetStateAction<IBattingPerformanceModel | null>>
  setDeletePerfOpen: React.Dispatch<React.SetStateAction<boolean>>
  setDeletePerf: React.Dispatch<React.SetStateAction<IBattingPerformanceModel | IBowlingPerformanceModel | null>>
}

export interface FieldingMetricsProps {
  inning: IInningModel
  game: IClspMatchModel
}

export interface StandardProps {
  children: ReactNode
}

export interface InGameProps {
  children: ReactNode
  socketStatus: 'UNKNOWN' | 'OFFLINE' | 'PRIMARY' | 'SECONDARY'
}

export interface SetupProps {
  mode: ClspMode
  timelineEvents?: ITimelineMatchMapModel
}

export interface PostMatchProps {
  mode: ClspMode
}

export interface AppSettingsProps {
  game: IClspMatchModel
  mode: ClspMode
  isSetup?: boolean
}

export interface InGameAlternateProps {
  children: ReactNode
  socketStatus: 'UNKNOWN' | 'OFFLINE' | 'PRIMARY' | 'SECONDARY'
  mode: ClspMode
}

// TODO: Rename These Components

export interface PreBallProps {
  game: IClspMatchModel
  ball: IBallModel
  lastBall?: IBallModel
  timelineEvents: ITimelineMatchMapModel | undefined
  relevantTimelineEvents?: ITimelineEventModel[]
  confirmedBalls: IBallModel[]
  setCurrentBall: React.Dispatch<React.SetStateAction<IBallModel | undefined>>
  bowlerRunningIn?: (event: MouseEvent<HTMLButtonElement>) => void
  triggerEndOver: () => void
  betweenOvers?: boolean
  fieldHasChanged?: boolean
  setFieldHasChanged?: React.Dispatch<React.SetStateAction<boolean>>
  editBall?: boolean
  triggerEditBall?: (args: TriggerEditBallArgs) => void
  cancelEditBall?: (endOfOver?: boolean, resyncingBall?: boolean) => void
  currentBall?: IBallModel
  triggerUndo: (ball: IBallModel, undoOnly?: boolean) => void
  triggerUndoEvent?: (event: ITimelineEventModel, relatedEvent?: ITimelineEventModel) => void
  triggerMissedBall?: () => void
  triggerInsertBall?: (overNumber: number, ballNumber: number) => void
  insertingBall?: boolean
  inningsInOrder?: InningInOrder[]
  currentInning?: CurrentInning
  setCurrentInning?: React.Dispatch<React.SetStateAction<CurrentInning | undefined>>
  editingDisabled?: boolean
  activeInning: IInningModel
  closedInning?: boolean
  setClosedInning?: React.Dispatch<React.SetStateAction<boolean>>
  triggerNewBatter?: (type: BatterChangeType) => void
  triggerNewBowler: (type: BowlerChangeType) => void
  triggerNewFielder?: (playerMpId: string | null | undefined) => void
  completeChangeBowler?: (player: IMatchPlayerModel) => void
  batterPerformance?: IBattingPerformanceModel
  setBatterPerformanceToChange?: React.Dispatch<React.SetStateAction<IBattingPerformanceModel | undefined>>
  setBowlerPerformanceToChange?: React.Dispatch<React.SetStateAction<IBowlingPerformanceModel | undefined>>
  handlePenaltyRuns?: ({ value }: { value: string }) => void
  isDisabled?: boolean
  activityLogActionsDisabled?: boolean
  setEndOver?: React.Dispatch<React.SetStateAction<boolean>>
}
export interface InBallComponentProps {
  game: IClspMatchModel
  ball: IBallModel
  completeBall: (endOfOver?: boolean, incomplete?: boolean) => void
  resyncBall: () => void
  inBall: boolean
  editBall?: boolean
  ballRunsVal: number | null
  setBallRunsVal: React.Dispatch<React.SetStateAction<number | null>>
  ballExtrasVal: string | null
  setBallExtrasVal: React.Dispatch<React.SetStateAction<string | null>>
  isPrimaryEditCheck: () => void
  insertingBall: boolean
}

export interface InBallProps {
  game: IClspMatchModel
  ball: IBallModel
  lastBall?: IBallModel
  ballStore: IBallStoreModel
  mode: ClspMode
  timelineEvents?: ITimelineMatchMapModel | undefined
  completeBall: (endOfOver?: boolean, incomplete?: boolean) => void
  resyncBall: () => void
  cancelEditBall: (endOfOver?: boolean, resyncingBall?: boolean) => void
  editBall?: IBallModel
  editBallProps?: { isNewestOver?: boolean; isNewestBall?: boolean }
  deadBall?: (event: MouseEvent<HTMLButtonElement>) => void
  ballRunsVal: number | null
  setBallRunsVal: React.Dispatch<React.SetStateAction<number | null>>
  ballExtrasVal: string | null
  setBallExtrasVal: React.Dispatch<React.SetStateAction<string | null>>
  commentaryChanged?: boolean
  setCommentaryChanged?: React.Dispatch<React.SetStateAction<boolean>>
  activeInning: IInningModel
  handlePenaltyRuns?: ({ value }: { value: string }) => void
  confirmedBalls: IBallModel[]
  relevantTimelineEvents?: ITimelineEventModel[]
  triggerEndOver: () => void
  betweenOvers?: boolean
  triggerEditBall?: (args: TriggerEditBallArgs) => void
  triggerUndo?: (ball: IBallModel, undoOnly?: boolean) => void
  insertingBall?: boolean
  inningsInOrder?: InningInOrder[]
  currentInning?: CurrentInning
  setCurrentInning?: React.Dispatch<React.SetStateAction<CurrentInning | undefined>>
  isPrimaryEditCheck?: () => void
  isDisabled?: boolean
  activityLogActionsDisabled?: boolean
  fieldHasChanged?: boolean
  setFieldHasChanged?: React.Dispatch<React.SetStateAction<boolean>>
  triggerNewFielder?: (playerMpId: string | null | undefined) => void
}

export interface FootworkProps {
  ball: IBallModel
  incompleteData: boolean
}

export interface SocketModalProps {
  socketStore: ISocketStore
  matchId: string
  appMode: ClspMode
}

export const socketConnectionColors = {
  UNKNOWN: 'cls.blue.400',
  OFFLINE: 'gray.300',
  PRIMARY: 'green.400',
  SECONDARY: 'orange.400',
}

export interface MatchStartingModalProps {
  game: IMatchModel
  mode: ClspMode
}

interface ProgressStepsItem {
  text: string
  isComplete: boolean
  isClearable?: boolean
  onClear?: () => void
  labelClear?: string
}

export interface ProgressStepsProps {
  arrange: 'row' | 'column'
  itemWidth?: number | string
  items: ProgressStepsItem[]
}

export interface MatchNotesProps {
  game: IClspMatchModel
  notes: IMatchNotesModel[] | null
  balls: IBallStoreModel
  isScoringManually?: boolean
}

export type MatchValidationObject = {
  id: string
  name?: string
}

export type MatchValidationResponse = {
  match: Array<string | MatchValidationObject>
  competition: Array<string | MatchValidationObject>
  players: Array<string | MatchValidationObject>
  officials: Array<string | MatchValidationObject>
  team: Array<string | MatchValidationObject>
  venue: Array<string | MatchValidationObject>
  [key: string]: unknown[]
}

export type NotificationBoxProps = {
  id: string
  text: string
  buttonText: string
  buttonIcon?: IconProp
  onClick: React.MouseEventHandler<HTMLButtonElement>
  onCancel?: React.MouseEventHandler<HTMLDivElement>
}

export type S3PBrowserFilter = '' | 'id' | 'overBall'
export type S3PBrowserProps = {
  matchId: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
